
const Vue = require('vue')

const components = {
    PriceCalculatorWidget: require('./PriceCalculatorWidget/index.vue')
}

window.COMPONENT = function (name) {
    return {
        mount: function(el, data) {
            return new Vue({
                el,
                render: function(h) {
                    return h(components[name], {props: {data}})
                }
            })
        }
    }
}