<template>
    <div class="row no-gutter widget-pricecalculator">
        <div class="col-md-6 pr-2">
            <h2 class="section-title">
                {{ data.strings.stayPrice }}
                <small
                    style="font-size: 16px"
                >{{ data.strings.onBaseOf }}</small>
                <!--Prix du séjour-->
            </h2>

            <div>
                <template v-for="(t, i) in priceEntries">
                    <div class="row no-gutter section-item" :key="i" v-if="t.price != 0">
                        <div class="col-sm-9">{{ t.tag }}</div>
                        <div class="col-sm-3 text-right">
                            <b>{{ t.price }},00 €</b>
                        </div>
                    </div>
                    <div class="row no-gutter section-item section-item-free" :key="i" v-else>
                        <div class="col-sm-9">{{ t.tag }}</div>
                        <div class="col-sm-3 text-right">
                            <b>{{ data.strings.free }}</b>
                        </div>
                    </div>
                </template>
            </div>

            <div class="price-estimation-container mt-4">
                <div class="row">
                    <div class="col-lg-8">
                        {{ data.strings.totalPrice }}
                        <!--Prix total de mes vacances-->
                    </div>
                    <div class="col-lg-4 text-right">
                        <span class="price">
                            * {{ estimatedPrice }}
                            <small>,00</small> €
                        </span>
                        <span class="price-note">
                            {{ data.strings.chargesIncluded }}
                            <!--Charges & TVA comprises-->
                        </span>
                    </div>
                </div>
            </div>
            <span>
                <small>
                    * {{ data.strings.priceEstimation }}
                    <!-- Estimation des charges -->
                </small>
            </span>

            <p class="pt-4 mb-1">
                <i>
                    {{ data.strings.otherCosts }}
                    <!--Charges non calculables-->
                    :
                </i>
            </p>

            <div>
                <div class="row no-gutter section-item" v-for="(c, i) in data.otherCosts" :key="i">
                    <div class="col-md-9">{{ c[0] }}</div>
                    <div class="col-md-3 text-right">{{ c[1] }}</div>
                </div>
            </div>
        </div>

        <div class="col-md-6 pl-2">
            <div style="margin-top: 30px;"></div>

            <div class="dates-container">
                <div class="row no-gutter dates-selectors">
                    <div class="col-lg-6 pr-md-1 pb-1">
                        <!-- Type of stay selector (period) -->
                        <select v-model="dispPeriodType">
                            <option
                                v-for="(p, i) in data.periodTypes"
                                :key="i"
                                :value="i"
                            >{{ p.name }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 pl-md-1">
                        <!-- Month selector -->
                        <select v-model="dispMonthId">
                            <option
                                v-for="(p, i) in selectableMonths"
                                :key="i"
                                :value="i"
                            >{{ p[0] }}</option>
                        </select>
                    </div>
                </div>
                <table class="dates-display">
                    <tr>
                        <td class="calendar-arrow"></td>
                        <th v-for="(d, i) in daysInitials" :key="i">
                            <span class="text-muted">
                                <small>{{ d }}</small>
                            </span>
                        </th>
                        <td class="calendar-arrow"></td>
                    </tr>
                    <tr v-for="(week, i) in computedCalendar" :key="i">
                        <template v-if="i == 0">
                            <td
                                :rowspan="computedCalendar.length + 1"
                                class="calendar-arrow"
                                :class="{real: dispMonthId != 0}"
                                @click="prevMonth()"
                            >
                                <i class="fas fa-arrow-left" v-if="dispMonthId != 0"></i>
                            </td>
                        </template>

                        <td
                            v-for="(day, j) in week"
                            :key="j"
                            :class="day.class"
                            @click="day.handler && day.handler()"
                        >{{ day.content }}</td>

                        <template v-if="i == 0">
                            <td
                                :rowspan="computedCalendar.length + 1"
                                class="calendar-arrow"
                                :class="{real: dispMonthId != selectableMonths.length - 1}"
                                @click="nextMonth()"
                            >
                                <i
                                    class="fas fa-arrow-right"
                                    v-if="dispMonthId != selectableMonths.length - 1"
                                ></i>
                            </td>
                        </template>
                    </tr>
                </table>
                <ul class="dates-periods-list">
                    <li
                        v-for="(p, i) in dispPeriods"
                        :key="i"
                        :class="{active: p[2] == currPeriodActive}"
                        @click="currPeriodActive = p[2]"
                    >
                        <input type="radio" :value="p[2]" v-model="currPeriodActive">
                        {{
                        data.strings.fromXToX
                        .replace('$0', daysShort[p[0].getDay()] + ' ' + p[0].getDate() + '/' + (p[0].getMonth() + 1) + '/' + p[0].getFullYear())
                        .replace('$1', daysShort[p[1].getDay()] + ' ' + p[1].getDate() + '/' + (p[1].getMonth() + 1) + '/' + p[1].getFullYear())
                        }}
                    </li>
                </ul>
                <p class="mb-0 mt-2">{{ data.strings.calendarFooter }}</p>
            </div>
        </div>
    </div>
</template>

<script>
module.exports = {
    props: ["data"],
    data: function() {
        var self = this;
        var date = new Date();

        var daysShort = this.data.dayNames.slice();
        daysShort.unshift(daysShort.pop());

        return {
            daysInitials: this.data.dayNames.map(function(d) {
                return d[0];
            }),
            daysShort: daysShort,
            months: this.data.monthNames,
            years: [2019, 2020],

            dispPeriodType: 0,
            dispMonthId: 0,

            dispPeriodStart: null,
            dispPeriodLen: null,
            dispPeriods: [],

            currPeriodActive: null,
            currPeriodActiveDay: null
        };
    },
    computed: {
        selectableMonths: function() {
            var self = this;

            var years = self.years.map(function(y, i) {
                var months = self.months;

                months = months.map(function(m, j) {
                    return [m + " " + y, i, j];
                });

                if (new Date().getFullYear() == y) {
                    var m = new Date().getMonth();
                    months = months.filter(function(v, i) {
                        return i >= m;
                    });
                }

                return months;
            });

            return [].concat.apply([], years);
        },

        currMonth: function() {
            return this.selectableMonths[this.dispMonthId][2] + 1;
        },
        currYear: function() {
            return this.years[this.selectableMonths[this.dispMonthId][1]];
        },

        computedCalendar: function() {
            var self = this;
            var data = [];
            var displayedPeriods = [];

            // console.log(this.currYear, this.currMonth)

            var firstDayOfMonth = new Date(
                this.currYear,
                this.currMonth - 1,
                1
            );
            var lastDayOfMonth = new Date(this.currYear, this.currMonth, 0);

            // console.log('First day of month:', firstDayOfMonth.toDateString(), firstDayOfMonth.getDay())
            // console.log('Last day of month:', lastDayOfMonth.toDateString())

            // We need to fill in data from the month before, in grey
            if (firstDayOfMonth.getDay() != 1) {
                var daysToSub = firstDayOfMonth.getDay() - 1;
                if (daysToSub == -1) daysToSub = 6;
                // console.log('Days to fill:', daysToSub)

                var firstDayOfDisplay = new Date(
                    firstDayOfMonth.getFullYear(),
                    firstDayOfMonth.getMonth(),
                    -(daysToSub - 1)
                );
                // console.log(firstDayOfDisplay.toDateString())

                for (var i = 0; i < daysToSub; i += 1) {
                    data.push({
                        content: firstDayOfDisplay.getDate() + i,
                        date: new Date(
                            firstDayOfDisplay.getFullYear(),
                            firstDayOfDisplay.getMonth(),
                            firstDayOfDisplay.getDate() + i + 1
                        ),
                        class: "text-muted"
                    });
                }
            }

            for (var i = 0; i < lastDayOfMonth.getDate(); i += 1) {
                data.push({
                    content: i + 1,
                    date: new Date(
                        lastDayOfMonth.getFullYear(),
                        lastDayOfMonth.getMonth(),
                        i + 1
                    ),
                    class: "day-of-month"
                });
            }

            // If we don't end on a sunday, we need to add days
            if (lastDayOfMonth.getDay() != 0) {
                var daysToFill = 7 - lastDayOfMonth.getDay();
                // console.log('Days to fill: ' + daysToFill)
                // console.log('Last day of month: ' + lastDayOfMonth.getDay())

                for (var i = 0; i < daysToFill; i += 1) {
                    data.push({
                        date: new Date(
                            lastDayOfMonth.getFullYear(),
                            lastDayOfMonth.getMonth() + 1,
                            i + 1
                        ),
                        content: i + 1,
                        class: "text-muted"
                    });
                }
            }

            var checkedDays = 0;
            while (this.dispPeriodStart === null) {
                var p = this.data.periodTypes[0];
                this.dispPeriodLen = p.length;
                this.dispPeriodStart = p.startDay;
            }

            var count = 0;
            var isActive = 0;
            var activeCount = 0;
            var currStartDay = 0;
            var nextIsEnd = 0;

            for (var i = 0; i < data.length; i += 1) {
                var classes = [];
                if (data[i].class) classes.unshift(data[i].class);

                // If this is a possible start day for a period
                // Check if all days within this period are available
                if ((i + 1) % 7 == this.dispPeriodStart) {
                    var isFree = true;

                    if (data[i].date.getMonth() != this.currMonth - 1) {
                        isFree = false;
                    }

                    if (isFree) {
                        for (
                            var j = 0;
                            j <
                            this.dispPeriodLen -
                                (this.data.sameDayTurnaround ? 1 : 0);
                            j += 1
                        ) {
                            if (i + j > data.length - 1) break;
                            if (!this.checkDateFree(data[i + j].date))
                                isFree = false;
                        }
                    }

                    if (isFree) {
                        count = this.dispPeriodLen;
                        currStartDay = i;
                        classes.push("start");
                        displayedPeriods.push([
                            new Date(data[i].date.getTime()),
                            new Date(
                                data[i].date.getTime() +
                                    1000 * 60 * 60 * 24 * (count - 1)
                            ),
                            i + 1
                        ]);

                        // console.log('curr period active:', this.currPeriodActive)
                        if (this.currPeriodActive === null) {
                            this.currPeriodActive = i + 1;
                        }
                    }
                }

                if (count > 0) {
                    classes.push("could-be-active");
                }

                if (i + 1 == this.currPeriodActive) {
                    isActive = true;
                    activeCount = this.dispPeriodLen;
                    this.currPeriodActiveDay = data[i].date;
                }

                if (isActive) {
                    classes.push("active");

                    if (classes.indexOf("text-muted") > -1) {
                        classes.splice(classes.indexOf("text-muted"), 1);
                    }
                }

                if (nextIsEnd) {
                    classes.push("end");
                    nextIsEnd = false;
                }

                if (count == 2) {
                    nextIsEnd = true;
                }

                data[i].class = classes.join(" ");

                if (classes.indexOf("could-be-active") != -1) {
                    data[i].handler = (function() {
                        var sd = currStartDay + 1;
                        return function() {
                            self.currPeriodActive = sd;
                        };
                    })();
                }

                count -= 1;
                activeCount -= 1;
                if (activeCount == 0) {
                    isActive = 0;
                }
            }

            this.dispPeriods = displayedPeriods;
            return chunk(data, 7);
        },

        priceEntries: function() {
            var prices = this.data.priceTags.map(function(t) {
                return { tag: t[0], price: parseInt(t[1]) };
            });

            prices[0].price = "---";

            if (this.currPeriodActive !== null) {
                var price = 0;
                var season = this.getSeasonForDay(this.currPeriodActiveDay);

                price = parseInt(season.prices[this.dispPeriodType]);

                prices[0].price = price;
            }

            return prices;
        },
        estimatedPrice: function() {
            if (this.currPeriodActive === null) {
                return "--- €";
            }

            return this.priceEntries
                .map(function(a) {
                    return parseInt(a.price);
                })
                .reduce(function(a, b) {
                    return a + b;
                }, 0);
        }
    },
    watch: {
        dispPeriodType: function(val) {
            this.dispPeriodStart = this.data.periodTypes[val].startDay;
            this.dispPeriodLen = this.data.periodTypes[val].length;
            this.currPeriodActive = null;
        },
        dispMonthId: function() {
            this.currPeriodActive = null;
        }
    },
    methods: {
        checkDateFree: function(d) {
            var free =
                this.data.reservations.filter(function(r) {
                    return (
                        r[0].getTime() <= d.getTime() &&
                        d.getTime() <=
                            r[0].getTime() + 1000 * 60 * 60 * 24 * r[1]
                    );
                }).length === 0;

            return free;
        },

        getSeasonForDay: function(d) {
            var self = this;
            var matches = this.data.seasons.filter(function(s) {
                return (
                    s.periods.filter(function(p) {
                        p[0].setFullYear(self.currYear);
                        p[1].setFullYear(self.currYear);
                        return (
                            p[0].getTime() <= d.getTime() &&
                            d.getTime() <= p[1].getTime()
                        );
                    }).length !== 0
                );
            });

            if (matches.length > 0) {
                return matches[matches.length - 1];
            }

            return null;
        },

        prevMonth: function() {
            if (this.dispMonthId > 0) {
                this.dispMonthId -= 1;
            }
        },
        nextMonth: function() {
            if (this.dispMonthId < this.selectableMonths.length - 1) {
                this.dispMonthId += 1;
            }
        }
    }
};

var chunk = function(a, size) {
    var arrays = [];

    while (a.length > 0) arrays.push(a.splice(0, size));

    return arrays;
};
</script>

<style>
</style>
